import { ReactNode, useCallback, useMemo, useState } from "react";
import { SidebarMenuContext } from ".";
import { useBetaUI } from "logic/hooks/betaUI";
import { useAppDispatch } from "store/hooks";
import { resetProjectSettings, setProjectSettings } from "store/modules/projects/actions";
import { ProjectSettings } from "store/modules/projects/types";
import { useRouter } from "next/router";

type WorkspacePageContextProviderProps = {
  children: ReactNode;
  initialBetaUIDismissed?: boolean;
  initialBetaUIFeatureFlagEnabled?: boolean;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  isImpersonating?: boolean;
};

export const SidebarMenuContextProvider: React.FC<WorkspacePageContextProviderProps> = ({
  children,
  initialBetaUIDismissed,
  initialBetaUIFeatureFlagEnabled,
  ragEnabled,
  surveysEnabled,
  isImpersonating,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { toggleBetaUI, markBetaUIAsDismissed, getBetaUIEnabled } = useBetaUI();

  const [betaUIEnabled, _setBetaUIEnabled] = useState<boolean | undefined>(getBetaUIEnabled());
  const [betaUIDismissed, _setBetaUIDismissed] = useState<boolean | undefined>(
    initialBetaUIDismissed
  );

  const isSavedStudy = useMemo(() => router.query.studyId !== undefined, [router.query.studyId]);

  const setBetaUIEnabled = useCallback(() => {
    _setBetaUIEnabled((prev) => {
      if (!isSavedStudy) {
        if (prev !== true) {
          dispatch(resetProjectSettings());
        } else {
          dispatch(setProjectSettings(ProjectSettings.ProblemExplorationMode));
        }
      }
      toggleBetaUI(!prev);
      return !prev;
    });
  }, [toggleBetaUI, dispatch, isSavedStudy]);

  const setBetaUIDismissed = useCallback(() => {
    _setBetaUIDismissed(true);
    markBetaUIAsDismissed();
  }, [markBetaUIAsDismissed]);

  return (
    <SidebarMenuContext.Provider
      value={{
        betaUIEnabled,
        setBetaUIEnabled,
        betaUIDismissed,
        setBetaUIDismissed,
        betaUIFeatureFlagEnabled: initialBetaUIFeatureFlagEnabled,
        ragEnabled,
        surveysEnabled,
        isImpersonating,
      }}
    >
      {children}
    </SidebarMenuContext.Provider>
  );
};
