import { useCallback } from "react";
import { useAppDispatch } from "store/hooks";
import { resetCustomScript } from "store/modules/custom-script/actions";
import { resetResearchAssistant } from "store/modules/research-assistant/actions";
import { resetResearchGoal } from "store/modules/research-goal/actions";
import { resetProjectSettings } from "store/modules/projects/actions";
import { resetInsightsData } from "store/utils/reset-insights-data";
import { resetSelectedAudiences } from "store/modules/audiences/actions";
import { resetSelectedProblems } from "store/modules/problems/actions";
import { resetSelectedSolution } from "store/modules/solutions/actions";
import { resetStudy } from "store/modules/study/actions";
import { resetStudyVisualization } from "store/modules/study-visualization/actions";

export const useHandleNewStudy = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(resetProjectSettings());
    dispatch(resetResearchAssistant());
    dispatch(resetSelectedAudiences());
    dispatch(resetSelectedProblems());
    dispatch(resetSelectedSolution());
    dispatch(resetResearchGoal());
    dispatch(resetCustomScript());
    dispatch(resetStudy());
    dispatch(resetStudyVisualization());
    resetInsightsData();
  }, [dispatch]);
};
