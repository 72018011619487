import { faFilter, faHistory, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getHistory } from "store/modules/history/actions";
import { resetAllData } from "store/utils/reset-all-data";
import { HISTORY_ROUTE } from "templates/history/history.routes";

import { useAuthState } from "logic/auth/auth-state";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { useHandleNewStudy } from "logic/hooks/betaUI/handle-new-study";
import { useSidebarMenuContext } from "../context";
import { SURVEYS_ROUTE } from "templates/surveys/survey.routes";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import { ProjectsMenu } from "./projects-menu";
import { STUDY_ROUTE } from "templates/studies/study.routes";

export enum TopMenuKeys {
  HOME = "home",
  SURVEYS = "surveys",
  SEE_ALL_HISTORY = "see-all-history",
  RECENT_STUDIES = "recent-studies",
  RAG = "rag",
}

export const BETA_UI_BANNER_HEIGHT = 26;

export const TopMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const auth = useAuthState();
  // Router
  const router = useRouter();

  const workspaces = useAppSelector((state) => state.workspaces.data);

  const history = useAppSelector((state) => state.history);

  const projectId = useAppSelector((state) => state.projects.data.project?.id);

  const activeSubscription = useAppSelector(
    (state) => state.workspaces.data.workspace?.activeSubscription
  );

  const isAnonymous = useMemo(
    () => auth.session.data?.user.isAnonymous,
    [auth.session.data?.user.isAnonymous]
  );

  const { betaUIEnabled, ragEnabled, surveysEnabled } = useSidebarMenuContext();

  // is saved study memoized variable
  const isSavedStudy = useMemo(() => router.query.studyId !== undefined, [router.query.studyId]);

  const handleNewStudy = useHandleNewStudy();

  // API Call
  useEffect(() => {
    if (projectId && !history.data && !history.loading && !history.error) {
      dispatch(getHistory({ projectId: projectId }));
    }
  }, [projectId, dispatch, history]);

  const homeMenuItems: Required<MenuProps>["items"] = [
    {
      label: "Home",
      key: TopMenuKeys.HOME,
      icon: <FontAwesomeIcon icon={faHome} />,
    },
  ];

  // Top Menu Items
  const topMenuItems: Required<MenuProps>["items"] = useMemo(() => {
    // Home menu item
    const surveys: ItemType = {
      label: "Surveys",
      key: TopMenuKeys.SURVEYS,
      icon: <FontAwesomeIcon icon={faWpforms} />,
    };

    // Recent Studies (Collapsible menu item)
    const recent: ItemType = {
      label: "Recent studies",
      key: TopMenuKeys.RECENT_STUDIES,
      icon: <FontAwesomeIcon icon={faHistory} />,
      children: history.data
        ?.slice(0, 3)
        .map((item) => ({
          label: item.description, // replace 'title' with the actual property name
          key: `${TopMenuKeys.RECENT_STUDIES}_${item.id}`,
        }))
        .concat({
          label: "See all history",
          key: TopMenuKeys.SEE_ALL_HISTORY,
        }),
    };

    // Rag menu item
    const rag: ItemType = {
      label: "Enrich your users (RAG)",
      key: TopMenuKeys.RAG,
      icon: <FontAwesomeIcon icon={faFilter} />,
    };

    const items: ItemType[] = [];

    if (!isAnonymous) {
      items.push(recent);

      if (activeSubscription) {
        if (ragEnabled) {
          items.push(rag);
        }

        if (surveysEnabled) {
          items.push(surveys);
        }
      }
    }

    return items;
  }, [isAnonymous, history, ragEnabled, surveysEnabled, activeSubscription]);

  // Top Menu Items Selected Keys
  const topMenuItemsSelectedKeys = useMemo(() => {
    const isHistory = router.asPath.startsWith("/history");
    const isRag = router.asPath.startsWith("/rag");
    const isSurveys = router.asPath.startsWith("/surveys");
    const workspaceKey = workspaces.workspace && `workspace-${workspaces.workspace?.id}`;
    const studyKey = router.query.studyId
      ? `${TopMenuKeys.RECENT_STUDIES}_${router.query.studyId as string}`
      : "";

    const selectedKeys = [];

    if (workspaceKey) selectedKeys.push(workspaceKey);
    if (studyKey) selectedKeys.push(studyKey);
    if (isSurveys) selectedKeys.push(TopMenuKeys.SURVEYS);
    if (isHistory) selectedKeys.push(TopMenuKeys.SEE_ALL_HISTORY);
    if (isRag) selectedKeys.push(TopMenuKeys.RAG);

    return selectedKeys;
  }, [router.asPath, workspaces.workspace, router.query.studyId]);

  // Menu Select Handler
  const onMenuSelect = useCallback(
    (key: string) => {
      if (key.startsWith(TopMenuKeys.RECENT_STUDIES)) {
        const id = key.split("_")[1];
        if (!id) return;
        router.push(STUDY_ROUTE.getHref(id));
        return;
      }

      switch (key) {
        case TopMenuKeys.HOME:
          analytics.track("workspace:sidebar:home");
          router.push(INDEX_ROUTE.getHref());
          if (betaUIEnabled) {
            handleNewStudy();
          }
          // Reset all data if navigating from saved study to home
          isSavedStudy && resetAllData();
          break;
        case TopMenuKeys.SURVEYS:
          analytics.track("workspace:sidebar:surveys");
          router.push(SURVEYS_ROUTE.getHref());
          break;
        case TopMenuKeys.SEE_ALL_HISTORY:
          analytics.track("workspace:sidebar:all-history");
          router.push(HISTORY_ROUTE.getHref());
          break;
      }
    },
    [router, isSavedStudy, betaUIEnabled, handleNewStudy]
  );

  return (
    <>
      {/* Top Menu */}
      <Menu
        selectedKeys={topMenuItemsSelectedKeys}
        className="layout-menu"
        theme="dark"
        style={{ backgroundColor: "transparent" }}
        mode="vertical"
        items={homeMenuItems}
        onClick={(info) => onMenuSelect(info.key)}
      />
      <ProjectsMenu />
      <Menu
        selectedKeys={topMenuItemsSelectedKeys}
        className="layout-menu"
        theme="dark"
        defaultOpenKeys={[TopMenuKeys.RECENT_STUDIES]}
        style={{ backgroundColor: "transparent" }}
        mode="inline"
        items={topMenuItems}
        onClick={(info) => onMenuSelect(info.key)}
      />
    </>
  );
};
