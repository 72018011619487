import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Form, Input, theme } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  sendEmailVerification,
  SAMLAuthProvider,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { FirebaseError } from "@firebase/util";
import { getAnalytics } from "logic/analytics/analytics";
import { getFirebaseAuth } from "logic/internals/apis/firebase/firebase-auth";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { z } from "zod";
import googleLogo from "./google-logo.svg";
import { ForgotPassword } from "./forgot-password";
import { useAppDispatch } from "store/hooks";
import { getCurrentUser } from "store/modules/users/actions";
import { getSsoProviderDomainByEmail } from "store/modules/sso-provider/actions";
import { WORKSPACE_SIGNUP_ROUTE } from "templates/workspace-settings/workspace-settings.routes";

export enum AuthTabType {
  SignUp = "signUp",
  Login = "login",
  SSO = "sso",
}

enum LoginProviders {
  Google = "google",
  SSO = "sso",
  OIDC = "oidc",
  SAML = "saml",
}

type AuthTabBaseProps = {
  type: AuthTabType;
  setEnableTabs: (enable: boolean) => void;
  showForgotPassword?: boolean;
  isMobile?: boolean;
};

type SubmissionStatus = {
  type?: "email" | "google" | "sso";
  status?:
    | "loading"
    | "login-wrong-credentials"
    | "signup-wrong-credentials"
    | "user-not-found"
    | "sso-not-found";
};

export type AuthTabProps = Omit<AuthTabBaseProps, "type">;

export const AuthTabBase: React.FC<AuthTabBaseProps> = ({
  type,
  setEnableTabs,
  showForgotPassword,
  isMobile,
}) => {
  const analytics = getAnalytics();
  const router = useRouter();

  const { token } = theme.useToken();

  const isSignUp = useMemo(() => type === AuthTabType.SignUp, [type]);
  const isSSOLogin = useMemo(() => type === AuthTabType.SSO, [type]);

  const [renderForgotPassword, setRenderForgotPassword] = useState(showForgotPassword || false);

  const [renderSSOButton] = useState(isSSOLogin);

  const [submission, setSubmission] = useState<SubmissionStatus>();

  const form = useForm<{ email: string; password: string }>({
    resolver: zodResolver(
      z.object({
        email: z.string().email(),
        password: isSignUp
          ? z
              .string()
              .refine((value) => value.length >= 6, "Your password must have at least 6 characters")
          : z.string(),
      })
    ),
  });

  const dispatch = useAppDispatch();

  const onLoginWithProvider = useCallback(
    async (providerName: LoginProviders) => {
      if (providerName === LoginProviders.SSO) {
        setSubmission({ type: "sso", status: "loading" });
        const auth = getFirebaseAuth();

        const domainResult = await dispatch(
          getSsoProviderDomainByEmail({ email: form.getValues().email })
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (domainResult.payload?.data?.ssoProvider === undefined) {
          setSubmission({ status: "sso-not-found" });
          return;
        }

        // eslint-disable-next-line
        const providerId = domainResult.payload?.data?.ssoProvider?.providerId;
        if (providerId === undefined) {
          setSubmission({ status: "sso-not-found" });
          return;
        }
        const provider = // eslint-disable-next-line
        providerId.startsWith("oidc")
          // eslint-disable-next-line
          ? new OAuthProvider(providerId)
          : // eslint-disable-next-line
            providerId.startsWith("saml")
            // eslint-disable-next-line
            ? new SAMLAuthProvider(providerId)
            : null;

        if (provider === null) {
          setSubmission({ status: "sso-not-found" });
          return;
        }

        analytics.track(`sign-up:sign-in-with-${providerName}`);

        await signInWithPopup(auth, provider);

        dispatch(getCurrentUser());
        router.push(
          EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && !isMobile
            ? WORKSPACE_SIGNUP_ROUTE.getHref()
            : INDEX_ROUTE.getHref()
        );
      }
      if (providerName === LoginProviders.Google) {
        setSubmission({ type: "google", status: "loading" });
        const provider = new GoogleAuthProvider();
        const auth = getFirebaseAuth();

        try {
          if (isSignUp) {
            // Sign up
            auth.currentUser && (await linkWithPopup(auth.currentUser, provider));

            analytics.track(`sign-up:sign-up-with-${providerName}`);
          } else {
            // Log in
            await signInWithPopup(auth, provider);

            analytics.track(`sign-up:sign-in-with-${providerName}`);
          }

          // redirect to the index page after the user signs up or logs in
          dispatch(getCurrentUser());
          router.push(
            EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && !isMobile
              ? WORKSPACE_SIGNUP_ROUTE.getHref()
              : INDEX_ROUTE.getHref()
          );
        } catch (_err) {
          const error = _err as { [key: string]: unknown };

          // if the user is already signed up, we should sign in instead
          if (error.code === "auth/credential-already-in-use") {
            const credential = GoogleAuthProvider.credentialFromError(_err as FirebaseError);
            credential && (await signInWithCredential(auth, credential));

            analytics.track(`sign-up:sign-in-with-${providerName}`);

            dispatch(getCurrentUser());
            router.push(
              EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && !isMobile
                ? WORKSPACE_SIGNUP_ROUTE.getHref()
                : INDEX_ROUTE.getHref()
            );
            return;
          }

          if (
            error.code === "auth/popup-closed-by-user" ||
            error.code === "auth/cancelled-popup-request"
          ) {
            // NO-OP
            return;
          }

          // if none of the above, throw the error
          throw error;
        }
      }
    },
    // eslint-disable-next-line
    [analytics, isMobile, isSignUp, router, dispatch]
  );

  const onLoginWithEmail = form.handleSubmit(async (data) => {
    setSubmission({ type: "email", status: "loading" });
    setEnableTabs(false);
    const credential = EmailAuthProvider.credential(data.email, data.password);
    const auth = getFirebaseAuth();

    if (isSignUp) {
      // Sign up
      try {
        if (auth.currentUser) {
          // create a new user with the email and password
          const res = await linkWithCredential(auth.currentUser, credential);
          // if the user is not verified, send a verification email
          if (!res.user.emailVerified) {
            await sendEmailVerification(res.user, {
              url: `${location.origin}/${
                EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && isMobile
                  ? WORKSPACE_SIGNUP_ROUTE.getHref()
                  : ""
              }`,
            });
          }

          // Sign in the user
          await signInWithEmailAndPassword(auth, data.email, data.password);

          analytics.track(`sign-up:sign-up-with-email`);

          dispatch(getCurrentUser());
          router.push(
            EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && !isMobile
              ? WORKSPACE_SIGNUP_ROUTE.getHref()
              : INDEX_ROUTE.getHref()
          );

          // Telmo: I'm not sure if this is the best way to do this, but it works
          router.reload();
        } else {
          throw new Error("Invalid user");
        }
      } catch (_err) {
        const error = _err as { [key: string]: unknown };

        // If email is already in use, try to sign in instead
        if (error.code === "auth/email-already-in-use") {
          try {
            await signInWithEmailAndPassword(auth, data.email, data.password);
            analytics.track(`sign-up:sign-in-with-email`);

            router.push(
              EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && !isMobile
                ? WORKSPACE_SIGNUP_ROUTE.getHref()
                : INDEX_ROUTE.getHref()
            );
          } catch (_err) {
            const err = _err as { [key: string]: unknown };
            if (err.code === "auth/wrong-password") {
              setSubmission({ status: "signup-wrong-credentials" });
              setRenderForgotPassword(true);
            }
          }
        } else {
          throw error;
        }
      }
    } else {
      // Log in
      try {
        await signInWithEmailAndPassword(auth, data.email, data.password);
        analytics.track(`sign-up:sign-in-with-email`);

        dispatch(getCurrentUser());
        router.push(
          EnvironmentVariables.MULTI_WORKSPACES_MODE === "true" && !isMobile
            ? WORKSPACE_SIGNUP_ROUTE.getHref()
            : INDEX_ROUTE.getHref()
        );
      } catch (_err) {
        const error = _err as { [key: string]: unknown };

        switch (error.code) {
          case "auth/user-not-found":
            setSubmission({ status: "user-not-found" });
            break;
          case "auth/wrong-password":
            setSubmission({ status: "login-wrong-credentials" });
            break;
          default:
            throw error;
        }

        setEnableTabs(true);
      }
    }
  });

  const alertProps = useMemo(() => {
    switch (submission?.status) {
      case "login-wrong-credentials":
        return {
          message: "Wrong credentials",
        };
      case "signup-wrong-credentials":
        return {
          message: "Wrong credentials",
          description:
            "This email is already signed up but the password is incorrect. You can reset your password below.",
        };
      case "user-not-found":
        return {
          message: "This email is not registered.",
          description: "Please sign up first.",
        };
      case "sso-not-found":
        return {
          message: "No SSO enabled workspace could be found.",
        };
    }
  }, [submission?.status]);

  return (
    <>
      <form onSubmit={onLoginWithEmail}>
        <Form.Item
          hasFeedback={!!form.formState.errors.email?.message}
          validateStatus={form.formState.errors.email?.message ? "error" : undefined}
          help={form.formState.errors.email?.message}
          style={{ marginBottom: token.marginSM }}
        >
          <Controller
            name="email"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Input
                prefix={<AntIcon icon={faEnvelope} />}
                placeholder="Email"
                type="email"
                style={{ flex: "1 1 auto" }}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Form.Item>
        {!isSSOLogin && (
          <Form.Item
            hasFeedback={!!form.formState.errors.password?.message}
            validateStatus={form.formState.errors.password?.message ? "error" : undefined}
            help={form.formState.errors.password?.message}
            style={{ marginBottom: token.marginSM }}
          >
            <Controller
              name="password"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <Input
                  prefix={<AntIcon icon={faKey} />}
                  placeholder="Password"
                  type="password"
                  style={{ flex: "1 1 auto" }}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Form.Item>
        )}

        {alertProps && (
          <Alert
            type="error"
            style={{
              marginBottom: token.marginSM,
            }}
            {...alertProps}
          />
        )}

        {!isSSOLogin && (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: token.marginSM,
            }}
          >
            <Button
              loading={submission?.status === "loading" && submission.type === "email"}
              disabled={submission?.status === "loading"}
              htmlType="submit"
              type="primary"
              style={{ flex: "1 1 auto" }}
            >
              {isSignUp ? "Sign up" : "Log in"}
            </Button>
          </div>
        )}
      </form>
      {!isSSOLogin && (
        <div
          style={{
            marginBottom: token.marginSM,
          }}
        >
          <Button
            style={{ width: "100%" }}
            onClick={(e) => {
              e.preventDefault();
              onLoginWithProvider(LoginProviders.Google);
            }}
            loading={submission?.status === "loading" && submission.type === "google"}
            disabled={submission?.status === "loading"}
            icon={
              <AntIcon
                component={() => (
                  // eslint-disable-next-line @next/next/no-img-element
                  <img
                    style={{ marginBottom: "5px" }}
                    height="20px"
                    src={(googleLogo as { src: string }).src}
                    alt="Google"
                  />
                )}
              />
            }
          >
            {isSignUp ? "Sign Up" : "Log in"} with Google
          </Button>
        </div>
      )}
      {renderSSOButton && (
        <div
          style={{
            marginBottom: token.marginSM,
          }}
        >
          <Button
            style={{ width: "100%" }}
            onClick={(e) => {
              e.preventDefault();
              onLoginWithProvider(LoginProviders.SSO);
            }}
            loading={submission?.status === "loading" && submission.type === "email"}
            disabled={submission?.status === "loading"}
            htmlType="submit"
            type="primary"
          >
            SSO Log in
          </Button>
        </div>
      )}
      <div></div>
      {renderForgotPassword && (
        /* 
          The page centers its content vertically. 
          Keep the ForgotPassword element to keep the tab the same size as the LoginTab,
          so that the content doesn't shift it's position when switching between tabs
        */
        <div
          style={{
            marginBottom: token.marginSM,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ForgotPassword />
        </div>
      )}
    </>
  );
};
