import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const DISMISSED_NEW_UI_KEY = "new-ui:dismissed";
const ENABLED_NEW_UI_KEY = "new-ui:enabled";

export function useBetaUI() {
  const localStorage = useLocalStorage();

  const dismissedBetaUI = useMemo(
    () => localStorage.getItem(z.boolean({ coerce: true }).optional(), DISMISSED_NEW_UI_KEY),
    [localStorage]
  );

  const markBetaUIAsDismissed = useCallback(() => {
    localStorage.setItem(DISMISSED_NEW_UI_KEY, true);
  }, [localStorage]);

  const getBetaUIEnabled = useCallback(
    () => localStorage.getItem(z.boolean({ coerce: true }).optional(), ENABLED_NEW_UI_KEY) ?? true,
    [localStorage]
  );

  const toggleBetaUI = useCallback(
    (value: boolean) => {
      localStorage.setItem(ENABLED_NEW_UI_KEY, value);
    },
    [localStorage]
  );

  return useMemo(
    () => ({
      getBetaUIEnabled,
      toggleBetaUI,
      dismissedBetaUI,
      markBetaUIAsDismissed,
    }),
    [getBetaUIEnabled, toggleBetaUI, dismissedBetaUI, markBetaUIAsDismissed]
  );
}
