import { Modal, Tabs, Typography } from "antd";
import React, { useState } from "react";
import { AuthTabBase, AuthTabType } from "../tab/base";
import { useToken } from "components/ui-kit/core/token";
import { AuthTabParams } from "..";

type LoginModalUIProps = {
  title?: string;
  description?: string | React.ReactNode;
  open?: boolean;
  defaultTab?: AuthTabParams;
  onCancel?: () => void;
};

export const LoginModalUI: React.FC<LoginModalUIProps> = ({
  title,
  description,
  open,
  defaultTab,
  onCancel,
}) => {
  const token = useToken();
  const [enableTabs, setEnableTabs] = useState(true);

  const tabs = [
    {
      key: AuthTabParams.SignUp,
      label: `Sign up`,
      disabled: !enableTabs,
      children: <AuthTabBase type={AuthTabType.SignUp} setEnableTabs={setEnableTabs} />,
    },
    {
      key: AuthTabParams.Login,
      label: `Log in`,
      disabled: !enableTabs,
      children: (
        <AuthTabBase type={AuthTabType.Login} setEnableTabs={setEnableTabs} showForgotPassword />
      ),
    },
    {
      key: AuthTabParams.SSO,
      label: `SSO Log in`,
      disabled: !enableTabs,
      children: <AuthTabBase type={AuthTabType.SSO} setEnableTabs={setEnableTabs} />,
    }
  ];

  return (
    <Modal footer={null} onCancel={onCancel} title={title} open={open}>
      {description && (
        <Typography.Paragraph style={{ marginBottom: token.paddingLG }}>
          {description}
        </Typography.Paragraph>
      )}
      <Tabs
        size="middle"
        defaultActiveKey={defaultTab}
        centered
        destroyInactiveTabPane
        items={tabs}
      />
    </Modal>
  );
};
