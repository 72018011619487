import { useAuthState } from "logic/auth/auth-state";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getLatestWorkspace,
  getWorkspacesList,
  setWorkspaceMode,
} from "store/modules/workspaces/actions";
import { useRouter } from "next/router";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useCurrentWorkspaceKeyInput } from "./current-workspace-key-input";

const useGetWorkspace = () => {
  const dispatch = useAppDispatch();
  const auth = useAuthState();
  const router = useRouter();
  const workspaceId = useAppSelector((state) => state.workspaces.data.workspace?.id);
  const workspacesList = useAppSelector((state) => state.workspaces.data.workspacesList);
  const workspacesLoading = useAppSelector((state) => state.workspaces.loading);
  const workspacesError = useAppSelector((state) => state.workspaces.error);
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);

  const { getWorkspaceKeyInput, removeWorkspaceKeyInput } = useCurrentWorkspaceKeyInput();

  const localStorageWorkspaceKey = useMemo(() => getWorkspaceKeyInput(), [getWorkspaceKeyInput]);

  const { shared, studyId } = router.query;

  const isShared = useMemo(() => shared === "true", [shared]);

  const isSavedStudy = useMemo(() => studyId !== undefined, [studyId]);

  const isAnonymous = useMemo(
    () => auth.session.data?.user.isAnonymous,
    [auth.session.data?.user.isAnonymous]
  );

  const user = auth.session.data?.user;

  useEffect(() => {
    if (
      user &&
      !user.isAnonymous &&
      !workspacesList?.length &&
      !workspacesLoading &&
      !workspacesError
    ) {
      dispatch(getWorkspacesList());
    }
  }, [user, workspacesList, workspacesLoading, workspacesError, dispatch]);

  useEffect(() => {
    if (!workspaceId && !workspacesLoading && !workspacesError && user) {
      dispatch(getLatestWorkspace());
    }
  }, [workspaceId, workspacesLoading, dispatch, workspacesError, user, isAnonymous]);

  // cleanup workspace key input from local storage
  useEffect(() => {
    if (localStorageWorkspaceKey) {
      removeWorkspaceKeyInput();
    }
  }, [localStorageWorkspaceKey, removeWorkspaceKeyInput]);

  useEffect(() => {
    if (isShared && workspaceMode !== WorkspaceMode.SharedUrl) {
      dispatch(setWorkspaceMode(WorkspaceMode.SharedUrl));
    } else if (isAnonymous && !isShared && workspaceMode !== WorkspaceMode.AnonymousUser) {
      dispatch(setWorkspaceMode(WorkspaceMode.AnonymousUser));
    } else if (
      !isAnonymous &&
      isSavedStudy &&
      !isShared &&
      workspaceMode !== WorkspaceMode.SavedStudy
    ) {
      dispatch(setWorkspaceMode(WorkspaceMode.SavedStudy));
    } else if (!isAnonymous && !isSavedStudy && workspaceMode !== WorkspaceMode.Regular) {
      dispatch(setWorkspaceMode(WorkspaceMode.Regular));
    }
  }, [isAnonymous, dispatch, isShared, workspaceMode, isSavedStudy]);
};

export default useGetWorkspace;
